import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Icon, Text } from "@atoms";
import { useScroll } from "framer-motion";
import useLang from "@hooks/useLang";
import t from "@utils/t";
import Thumbprint from "../atoms/Thumbprint";

const regions = {
  africa: { orgs: 80, amount: 3110000, label: "Africa" },
  "asia-pacific": { orgs: 8, amount: 343750 + 945140, label: "Asia & Pacific" },
  caribbean: { orgs: 26, amount: 587789, label: "Caribbean" },
  europe: { orgs: 4, amount: 732000, label: "Europe" },
  "latin-america": { orgs: 7, amount: 1547900, label: "Latin America" },
  "middle-east": { orgs: 6, amount: 380000, label: "Middle East" },
  multi: { orgs: 5, amount: 1534444, label: "Multi-Regional" },
};

const Map = ({ label, heading, subheading, copy }) => {
  const lang = useLang();
  const [activeRegions, setActiveRegions] = useState([]);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["1 1", "1 0"],
  });

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  const removeRegion = r => setActiveRegions(a => (a === r ? null : a));
  const setRegion = r => setActiveRegions(a => r);

  return (
    <div>
      <div className="relative z-10">
        <Text
          variant="lg"
          className={classNames("mb-14 font-bold uppercase text-white", {})}
        >
          {label}
        </Text>
        <Text
          variant="h2"
          className={classNames("mb-6 text-white", {})}
          richText
        >
          {heading}
        </Text>
        <div ref={ref} className="mb-10 flex flex-col gap-6 sm:flex-row">
          <Text
            variant="h6"
            className={classNames("text-white sm:w-1/2", {})}
            richText
          >
            {subheading}
          </Text>
          <Text
            variant="body"
            className={classNames(
              "prose max-w-lg text-white sm:w-1/2 sm:pl-6",
              {}
            )}
            richText
          >
            {copy}
          </Text>
        </div>
      </div>
      <div
        className={classNames("relative z-0 w-full", activeRegions, {
          "text-white/50": !!activeRegions,
          "text-white": !activeRegions,
        })}
      >
        <svg
          fill="transparent"
          className="absolute inset-0 z-20"
          viewBox="0 0 1163 591"
        >
          <g
            onMouseEnter={() => setRegion("latin-america")}
            onMouseLeave={() => removeRegion("latin-america")}
          >
            <path
              // className="latin-america"
              d="M849.1,25.7c4.8-1.5,9.8-6.5,11.3-11.3C857.8-2.5,832.2,23.2,849.1,25.7z"
            />
            <path
              // className="latin-america"
              d="M1078.6,385.3c-8.3-20.7-25.7-12.7-23.5-17.7c2.3-4.4,11-7.5,5.4-13.8c-19.1-18.1-6.1-9.2-25.6-12.6
              c-13.9-21.9-9.3-0.1-27.7-16.4c-6.4,6-18.7-6.7-17.7-11.8c6.5-8.8-8.5-12.5-10.8-19.3c-8.6-7.8-11.8,12.1-18.1,4.2
              c4.8-2,4.6-9.2-0.3-10.9c8.2-2.8-0.3-10.3,2.1-12.1c7.1-8.7,14.8-12,10.9-21.9c3-2.8,1.9-8.2-1.9-9.6c4.9-3.8,15.3-13.8,3.5-16.8
              c-10.2-14.1-12.6-9.4-12.6-25.7c-10.6-4-0.7-9.2-5.4-16.2c-13.5-14.3-33.3-24.8-32.7-43.7c-3.4-2,5.6-6.4,0-10.9
              c-3.8-1.8,2.6-3,1.7-6.8c0.9-3.6-5.6-5.1-1.9-6.7c6.6-10.1-11.9-11.1-3.7-20.5c-4.9-6.4,2.7-8.3-1.7-15.1
              c-2.8-2.4,6.3-8.6-1.9-12.2c11.1-7.5-6.4-14-8.8-20.3c-4.1-2,2.2-3,1.5-6.9c0.7-3.7-5.5-5-1.5-6.9c1.8-14.7-21.8-27.5-31.7-37.2
              c-4.9,2.5-22-3.5-15,10.1c36.3,25.5-5.3,23,14.6,34.4c1.7,6.5,4,19.4-3.8,22.7c-14.5,17.4-10.8,4.9-12.2,23.6
              c-15.3-15.6-20.7,28.8-22.3,35.1c6.2,9.3-5,12-10.4,19.6c-4.7-10.3-21.3,5.5-15.1,11.9c3.2,1.7-5.2,6.7,0,10.9
              c2.7,1.7-5.3,6.8-0.1,10.9c2.2,1.5-6,9.3,1.9,12.2c-18.1,18.5-11.6,7.1-14.5,26.8c-6,0.3-7.6,9.1-1.9,11.4
              c-12.3,13.8,6.9,8.4,12.8,11.4c10,22,23.2-3.4,38.5,5.5c14-6.4,1.5,1.9,6.8,9.1c-7.6,27.3,30,7.5,20.1,28.6
              c0,14.9,8.7,24.3,21.8,33.2c1.3,10,10.8,1.6,12.6,3.7c6.8,4.6,8.6-3.4,14.7,1.7c1.1,9.7,10.6,2,12.5,3.7c6.2,5.5,13.1-4.4,15.5-9.1
              c9.8-4.4,10.1,4.8,20.1-7.1c0.9-3.2,5.5,1.5,2.7,2.4c-5.6,4.2,3.4,9.2,0,10.9c-3,2.9-1.9,8.1,1.9,9.7c-8.5,3.6-0.1,16.2,6.5,9.6
              c3.5-6.6,6.2-6.4,12-3.8c4-1.3,9.4-4.1,6.8,0.8c-4.7,4.2,4.7,15-2.6,10.9c-5.1-6.5-25.8,13.7-19.2,19.2c20.8,10.1,28.8-23.2,40.1-4
              c-4.7,1.7-4.7,9-0.1,10.9c-3.2,4.4-12.9,8.5-7.2,15.1c4.5,3,9.9,12.3,15,3.5c5.4,8.1,10.3-1.5,16.4,3.7
              C1057.5,391.9,1072.5,403,1078.6,385.3z M1061.8,388.5c-2.8-4.7-11.2-6.8-5.5-11.6C1059.4,381.3,1067.3,383.6,1061.8,388.5z"
            />
          </g>
          <g
            onMouseEnter={() => setRegion("africa")}
            onMouseLeave={() => removeRegion("africa")}
          >
            <path
              // className="africa"
              d="M479.4,204c16.9,7.5,2.8-14.3,13.9-16.3c33.2-31.6,4.8-12,23.6-37c-2.5-6-2.4-4.8,0-10.8
              c-1.4-6.4-14.7-18.1-17.4-5.9c-16.5,8.9-6.4,12-10.9,21.9c-10.8,3-0.3,11-5.4,16.3c-10.1,1.3-1.6,10.8-3.7,12.7
              c-3,2.8-2,7.9,1.7,9.6C477.4,196,476.4,201.1,479.4,204z"
            />
            <path
              // className="africa"
              d="M731.5,363.6c4.7-1.8,4.7-9.3-0.1-11c4.6-2,4.7-8.9-0.1-10.9c4.9-1.8,4.9-9.1,0-11c5.5-2.2,4.2-11-1.8-11.3
              c-13.5-20.6,0.7-14.8-23.6-36.5c-8.7-9.9-12.7-7.8-20.4,1.9c-1.9-4.8-9.1-4.8-10.9,0c-2.2-5.7-11.1-4.2-11.4,1.9
              c-10.3,10.4-15.9-20.7-26.8-7.3c-5.5-4.1,4.1-6.9-1.3-12.8c-3.3-1.8,5.5-6.6,0-10.9c4.2-9.9-4-13.1-10.9-21.9
              c-3.3-1.9,5.5-6.6,0-10.9c-2.7-2,6.3-8.9-1.9-12.2c4.7-1.8,4.9-9.1,0.1-10.8c8.2-3-0.9-10.5,1.9-12.2c3-4.5,12.7-10,3.6-15.1
              c10.6-7.7-6.9-15.9-10.9-21.8c4.8-1.8,4.8-9.2-0.1-11c3.7-1.5,4.8-6.7,1.9-9.6c-1.8-2.8,6.5-11-3.8-12.8
              c-11.5-10.5-3.7-18.2-7.1-30.9c-2.8-3-8.2-1.9-9.6,1.9c-1.8-4.7-9.1-4.8-10.9,0c-4.9-9.3-14.7,2-16.8,7.3
              c-5.7,4.9-26.1,18-20.1,26.4c4.4,6.2-18.9,12.4-10.9,21.9c3.5,1.6-2.6,3.2-1.7,6.6c-0.8,4,5.8,5,1.8,6.8
              c-5.2,11.4-32.5,22.6-27.3,35.7c3.8,1.7-2.5,3.1-1.7,6.8c2.6,6.1,2.4,4.8,0,10.8c-0.5,5.4,11.4,8.8,7.3,12.3
              c-4.7,7.1,5.3,12.2-5.5,16.3c4.6,23.6-37.5,32-32.7,54.5c-8.6,3.7-10.7,37.6,4.1,22.8c5.2,8.9,10.5-0.7,15-3.5
              c13.6,0-1.9,12.3,24.4,32.8c14.3,10.3-8.9,21.6,25.9,23.5c6.2-2.7,4.8-2.5,10.9,0c6-2.3,4.6-3,10.5,0c0.2,6.1,9.2,7.6,11.4,2
              c1.8,4.6,9.1,4.7,10.9,0c5.8,4.1-4.3,6.7,1.2,12.7c3.3,5.1-15.2,11.8-3.6,17.7c-3.8,1.5-4.9,6.8-1.9,9.6c1.9,2.7-6.4,11.4,4.2,12.7
              c8.2-3.4,11.3-14,17.7-1.7c6.7,4.7,8.5-3.5,14.6,1.7c5.1,5.3-5.5,13.4,5.5,16.2c2.9,10.8,11.1,0.7,16.7,5.5
              c18.9-2.8,10,3.4,27.3-14.6c10.7,15.9,15.8,5.1,26-3.5c9.8-11,5-23.8,21.9-32.8c8.7-8.6,6.6-8.2,5.4-16.4
              C730.8,374.3,740.3,368.7,731.5,363.6z"
            />
          </g>
          <g
            onMouseEnter={() => setRegion("middle-east")}
            onMouseLeave={() => removeRegion("middle-east")}
          >
            <path
              // className="middle-east"
              d="M593.2,391.2c6-0.4,7.5-9.2,1.8-11.4c9.9-4.8-2.9-17.7-7.7-7.7c-1.5-3.8-6.8-4.9-9.6-1.9
              c-3.6,3.9-6.7-7.7-12.1-7.3c-6.2,2.5-4.8,2.7-10.9,0.1c-52.7,1.9,9.6,34.2-16,27.3c-25.6-26.3-0.3-11.9-18-31
              c1.8-8,2.3-10.1-7.4-17.6c11.5-11.3-16.5-32.3-28.2-9.6c-2,1.7-11.2-6.2-12.6,3.6c-5.4,4.5-29.4,20.7-18.3,27.8
              c-14.5,7.5,21.3,23.5,6.6,23.1c-4.5-2.8-10-12.4-15-3.5c-4.6-10.1-21.6,5.3-15.2,11.8c3.2,1.8-5.4,6.6,0,10.9
              c-3.4,9.8,4.6,17.4,14.7,23.6c5.4,4.8-6.8,34.2,10.1,25.5c1.6-3.2,6.8,5.4,10.9,0c1.5-2.2,9.3,5.9,12.1-1.9
              c25.2,17.7-10.8-38.8,34.1,12.8c4.3,5.4,9.2-3.3,10.9,0c6.4,5.6,11-3.9,15.1-7.2c4,4,11.2,14.4,16.4,5.3
              c8.6,11.9,42.5-24.9,16.4-30c-7.4,1.4-19.2,4.6-23.2-3.7c-3.8-1.8,2.6-3.1,1.7-6.8c-1.4-4-4-9.7,0.8-6.7c6.9,4.4,8.6-3.1,15.1,1.8
              c6.5-4.9,8.2,2.7,15.1-1.7c3.8,1.3,20.6,1.8,9-9.7C597.9,401.8,587.7,397,593.2,391.2z M471.4,384c2.8-2.6,27.3,21.5,24.4,24.5
              C492.7,411,468.8,387,471.4,384z"
            />
            <path
              // className="middle-east"
              d="M581.8,423c-7.7-0.1-7.7,11.9,0,11.8C589.4,434.9,589.4,422.9,581.8,423z"
            />
            <path
              // className="middle-east"
              d="M554.5,423c-7.7-0.1-7.7,11.9,0,11.8C562.2,434.9,562.2,422.9,554.5,423z"
            />
          </g>
          <g
            onMouseEnter={() => setRegion("asia-pacific")}
            onMouseLeave={() => removeRegion("asia-pacific")}
          >
            <path
              // className="asia-pacific"
              d="M573.2,521.7c9.8-4.9-2.8-17.7-7.7-7.7c-3.9-5-9.1-7.3-12.2-12.8c-2-7.6,16.1-13.8,18.1-23.1
              c-13-25.1-15.2,1.7-27.2-5.9c-2.1-4.7-9.5-14.7-15.5-9.2c-0.5,2.3-12.5-6.6-12.6,3.8c-13.2,1.9,5.1,20.1-10.1,10.9
              c-5.3-6.2,3-7.9-1.7-14.7c-6.1-2.5-13.1-19.9-20.5-9c-5.4-8.2-10.3,1.5-16.4-3.7c-6.1,5.2-11-4.5-16.4,3.7c-5.2-3.8,5.4-9.5-3-14.1
              c13.4-9.6-18-20-14.7-28.6c5.4-4.3-3.3-9.2,0-10.9c3-2.8,2-8-1.8-9.6c10.8-8.7-12-21.6-18.4-27.6c-3.2-9.5-17.9-11.3-10.8-21.5
              c-15.1-18.4-2.5-5.4-7.3-20.5c-10.6-4-0.7-9.4-5.4-16.3c-7.7-2.9-19.1-28.7-26.4-12.7c-10.3,1.4-1.6,10.7-3.8,12.7
              c-5.8,6.5,3.4,9.1,5.5,13.8c-5.7,12.1,6.5,11.1,5.4,19c-12.7,9.6-23.7,38.3-39,20c-1.5-4.8-6-6.6-9-10.5c10.1-4.7-3-18-7.8-7.7
              c-1.8-3.5-10.4-7.1-6.8-9.9c5.9-15.4-16.1-6.9,1.8-25.9c-0.6-6.6-21.3-18.3-10.1-17.7c22.7,19.3,15-9.8-0.7-15.6
              c-5.4-5.5,5.4-13.1-5.5-16.3c-4.9-5.9,2.9-8.1-1.7-14.6c-10.3-3.7-19.7-31.4-31.4-20c-4-4.2-11.1-14.3-16.4-5.5
              c-1.4-3.8-6.8-4.9-9.6-1.9c-3.4,4.6-6.8-7.6-12.3-7.1c-6.1,3-8.3,2.9-12.2-3.7c-5.8-7.4-25.7,14.6-19.3,19.2c2.8,3,8.1,1.9,9.6-1.8
              c1.7,4.9,9.1,4.8,11,0.1c1.8,4.7,9,4.7,10.9,0.1c5.7,8.2,10.1-2.3,16,3.3c8.6,20.8,22.2-0.3,23.6,17.2c-5.7,6.5,3.3,9.1,5.4,13.9
              c10.7,4.6,0.7,9.1,5.4,16.5c10.7,4.5,1,8.7,5.3,16.2c6.4,5.6,14.7,9.5,9.4,17.7c14.8,18.5,3.4,14.7-6.3,1.4
              c-8.8-6.4-12.5,8.5-19.3,10.8c-5.6,4.7,3.6,9.2,0.1,11c-5.7,6.1,4.5,13.1,9.1,15.5c0.8,6.4,12.5,8,6,13.6
              c-5.7-15.8-30.1-3.8-18.7,7.8c-13.2,14.2-39.4,29.7-16.5,49.1c-10.4,7.8,7.1,15.9,11.1,21.8c-4.7,1.9-4.8,9-0.1,10.9
              c-6.3,6.4-11.1-7.3-17.2-10.9c11.4-6.1-6.2-18.5-8.2-5.9c-14.9,5.2,4.8,16.6,8.9,22.3c-8.6,4.6,2.4,10.2-3.1,14.1
              c-3.5-8.5-16.2-0.2-9.6,6.4c3.9,1.8-2.4,3-1.6,6.8c5.8,9.8-2.1,9.1-6.4,1.2c1.9-7.7,2.2-8.6-5.4-16.3c-10.8-4.3-0.8-9.1-5.4-16.3
              c-2.2-5.1,11.6-13.8,16.2-18.9c6-11.2-14.2-23.4-20.9-4.7c-9.9,4.4-9.9-4.7-20.1,7.1c-7.6,7.4,10,12.3,5.5,16.5
              c-5.5,6.4,3.8,10.9,7.2,15.1c-12.3,11.8,11.3,17,16.3,16.3c-12.5,6.4,18.6,28.4,23.9,33.2c7.4,13.2,22.7-4.7,30.8,10.5
              c-5.8,5.4-6.5,9.6-14,5.1c-5.9,2-4.7,3.2-10.4,0c-4.1-15.8-14.8,6.4-18.2,1.7c-10.2-6.3-46.1-56.3-41-21.9
              c-8,9.5,15.3,15.6,10.9,21.7c-14.4-3.7-26.7,11.9-7.3,21c3.6,5.6,15.5,22.9,22.4,12.8c13.5,20.4,14.1-4.9,38.2,3.6
              c7.1-1.5,19.7-4.5,23.1,3.7c6.9,4.4,8.6-3.1,15.1,1.7c6.5-5,8.2,2.7,15.1-1.7c6-7.4-4.9,23.6,12.1,8.9c3.1,8,10.5-0.7,12.2,1.9
              c2.8,3,8.2,1.9,9.6-1.9c4.7,9.8,17.8-2.8,7.8-7.6c5.7-6.9,7.2,5.6,14,5.9c6.2-5.2,11,4.5,16.4-3.6c3,8,10.5-0.7,12.2,1.9
              c4.5,3,10,12.3,15,3.5c1.8,4.7,9.1,4.8,10.9,0c4,4.2,11,14.6,16.3,5.5c1.8,4.7,9.1,4.8,10.9,0c2.9,8,10.6-0.7,12.2,1.9
              c2.8,3,8.2,1.9,9.6-1.9c13.6,12.2,8.5-6.7,11.4-12.7c9.3-4.5,5.8,2,17.2-1.7c23.2,21.4,9.6-11,16.3-5.4c6.7,5.6,28.1-7.5,20.1,6.2
              c-5,5.1-14.4-5.5-16.4,6c-0.3,6.3,9.2,8.2,11.4,2.4c1.5,3.6,6.8,4.6,9.5,1.7c3-1.2,10.5,5.8,12.6-3.7c16.2-5.4-8.2-14.4-0.4-19.8
              c7.5,9.1,37-1.3,24.1-13.3C566.6,537.8,583.8,529.1,573.2,521.7z M258.7,444c1.3-3.4,11.5,6.8,8,8
              C265.5,455.6,254.8,445.3,258.7,444z M276.3,360.4c2.7,3.7,2.9,2.6,0,6.2C273.7,363,273.5,364,276.3,360.4z M332.1,572
              c-0.9,3.2-5.5-1.5-2.6-2.4C330.5,566.3,335.3,571.1,332.1,572z M456.3,579.4c-2.8-3.7-2.8-2.6,0-6.3
              C459.1,576.8,459.1,575.8,456.3,579.4z"
            />
            <path
              // className="asia-pacific"
              d="M206,287.1c-1.6-14.2-22.8,2.2-15.6,9.7c3.3,1.8-5.5,6.6,0,10.9c11.6,5.7-3.1,15.9,14.6,23.4
              c13.5,14.9,3.1,6.9,7.2,20.1c9.3,14.4,24.6,0.3,19.2-8.4c-11.7-4.1,1.6-12-7.3-17.7c8.2-5.1-1.6-10.2,3.6-15.9
              c10.8-2.9,0.4-11,5.4-16.4c6.5-7.9,43.6-29.7,27.4-39.1c-5.9-8.1-13.6-16.6-22.3-21.8c-16.7,14.7-12.3,2.3-27.4-5.5
              c-22.6,12.1-3.7,14.5-4.2,23.6c-12.9,14.4-22,19.4,4.2,20.9c6.7-0.4,8.3-12.5,14.1-5.9c-9.7,9.3,5.5,6.6-9.1,21.8
              c3.2,8,13.6,11.4,1.7,17.7c-1,3.2-5.8-1.6-2.6-2.6C223.3,282.5,195.1,303.4,206,287.1z M223,255.7c-7.9,2,0.5-6.4,4.2-9.9
              C231.6,249.7,227.1,253,223,255.7z M215,313.2c0.9-3.2,5.5,1.5,2.6,2.4C216.6,318.9,211.8,314.1,215,313.2z"
            />
            <path
              // className="asia-pacific"
              d="M565.4,581.3c-4.7,3.5-22.2-4.2-15.1,10.1c4.3,5.5,9.2-3.4,10.9,0C569.5,598.8,576.5,581.9,565.4,581.3z"
            />
            <path
              // className="asia-pacific"
              d="M14.5,161.2c-6.1,2.8-26.3,18.7-10.9,22.7C9.6,181,29.8,165.2,14.5,161.2z"
            />
            <path
              // className="asia-pacific"
              d="M221.2,379.8c5.3,17.5,29.8-9.8,6-16.8C215.8,365.4,226,374.2,221.2,379.8z"
            />
          </g>
          <g
            onMouseEnter={() => setRegion("caribbean")}
            onMouseLeave={() => removeRegion("caribbean")}
          >
            <path
              // className="caribbean"
              d="M854.5,260.4c6.3,0.1,8.3-9.1,2.3-11.4c5.9-2.3,4-11.5-2.3-11.4c-6.2,5.2-10.9-4.4-16.4,3.6
              c-11.6-11.3-14.3,5.9-4.3,11.9C845.4,266,843.3,255.7,854.5,260.4z"
            />
            <path
              // className="caribbean"
              d="M880.5,272c-4.4-2.8-10-12.4-15-3.5c-1.5-3.8-6.8-4.9-9.6-1.9c-3,2.8-2,8,1.8,9.6c-5.6,2.3-4,11.1,2,11.3
              c0.2,6,9.1,7.6,11.3,2c1.5,3.7,6.8,4.7,9.6,1.8c3-2.8,1.9-8.2-1.9-9.6C882.4,280.1,883.5,274.9,880.5,272z"
            />
            <path
              // className="caribbean"
              d="M969.1,363.1c-7.8,4.5-8.1,0.6-14.2-5.1c3.8-1.5,4.9-6.8,1.9-9.6c-4.1-5.4-9.1,3-10.8,0
              c-4.3-5.5-9.2,3.4-10.9,0c-4.3-5.5-9.2,3.4-10.9,0c-6.9-4.4-8.6,3.1-15.2-1.8c-14.6,7.5-0.2,13.6,6.8,10.1
              c10,11.8,10.2,2.8,20.2,7.3C948.8,394.3,989.8,373,969.1,363.1z"
            />
            <path
              // className="caribbean"
              d="M947.3,384.8c-7.7-0.1-7.7,11.9,0,11.8C955,396.8,955,384.7,947.3,384.8z"
            />
            <path
              // className="caribbean"
              d="M1007.7,336.2c-2.5-11.4-11.2-0.9-16.9-5.9c-15.3,4,4.8,19.9,10.9,22.8
              C1013.2,350.6,1002.7,341.9,1007.7,336.2z"
            />
            <path
              // className="caribbean"
              d="M1045.7,187.1c-2.6-11.7-11.2-0.4-16.5-5.9c-5.2-5.3,5.6-14.1-5.9-16.5c-6,5-11-4.4-16.3,3.6
              C973.8,150,1029.1,226.5,1045.7,187.1z"
            />
          </g>
          <path
            onMouseEnter={() => setRegion("europe")}
            onMouseLeave={() => removeRegion("europe")}
            // className="europe"
            d="M704,456.3c11.7-13-18-26.7-28.2-20.6c-3.8,7.7-51.9,50.5-29.8,24.8c5.8-6-7.7-19.7-13.8-13.9
            c-5,4.4,5.1,14.2-2.6,10.9c-10.9-4.2-0.9-9.2-5.5-16.3c-4.4-7.4-20-10.7-13.8,2.8c3.4,2-0.4,1.8-1.2,4.5
            c-37.1-39.7-7.9,13.6-32.7,1.9c-16,3,12.6,18.3-26,45.3c-6.8,5.9,16.1,28.7,20.5,17.4c4.4,4.1,0,3.4-0.5,8.5
            c10.6,11.5-20.7,16-7.2,27.3c-8.9,5.1,0.7,10.6,3.6,15c15.3,4.3,2.6-7,20.4,7.3c6.2-2.7,4.8-2.5,10.9,0c6.2-2.7,4.8-2.5,10.9,0
            c8.2-2.6,7.6-11.5,16.5-5.5c62.3-51.2,15.8-28,22.3-54.6c9.8-6.7,35.2-35.2,15.4-5.9c13.9,17.3,3.2,5.5,7.3,20.5
            c8.3,8.8,10.8,9,19.2,0c2.5-6.7,17.3-10.5,10.9-19.3c-16.5-13.3-5.2-11.2-23.7-12.1c8.8-5.2-0.7-10.5-3.6-15
            c-3.9-1.2,6.6-11.4,8-8.1c4.2,5.6,9.3-3.4,10.9,0C698.7,477.7,714.1,460.9,704,456.3z M602.4,547.7c19.8-17.9-12.8-11.9,7.1-30.9
            c13.4-6.7,1,2.6,6.3,9.2c2.8,1.8-6.3,8.9,1.8,12.1C613.5,541.9,600.3,557.8,602.4,547.7z"
          />
        </svg>
        <Icon name="world" className="relative z-10 w-full" />
        <div className="pointer-events-none absolute inset-0 -z-10 flex items-center justify-center opacity-50">
          <Thumbprint progress={scrollYProgress} align="center" />
        </div>
      </div>
      {/* controls */}
      <div className="relative z-10 my-6 flex flex-wrap justify-center gap-8 text-sm text-white">
        {Object.entries(regions).map(([k, v]) => (
          <button
            type="button"
            className="flex items-center gap-1"
            onClick={() => setRegion(k)}
          >
            <span
              className={classNames(
                "flex h-4 w-4 items-center justify-center rounded-full border-2 border-white p-[2px]"
              )}
            >
              <span
                className={classNames("block h-full w-full rounded-full", {
                  "bg-coral": activeRegions === k,
                })}
              />
            </span>
            <span>{v.label}</span>
          </button>
        ))}
      </div>
      {/* data */}
      <div className="relative z-10 flex flex-row flex-wrap divide-x-2 divide-white border-2 border-white text-center font-bold uppercase tabular-nums">
        <div className="w-full md:w-1/3">
          <div className="bg-coral p-3 text-sm">{t("Region", lang)}</div>
          <div className="h-10 p-3 text-white">
            {regions[activeRegions]?.label}
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="bg-light-green p-3 text-sm">
            {t("Organizations Funded", lang)}
          </div>
          <div className="h-10 p-3 text-white">
            {regions[activeRegions]?.orgs}
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="bg-black p-3 text-sm text-white">
            {t("Amount Distributed in CAD", lang)}
          </div>
          <div className="h-10 p-3 text-white">
            ${regions[activeRegions]?.amount?.toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
